<template>
  <div>
    <v-simple-table>
      <template v-slot:default v-if="items.length">
        <thead>
          <tr>
            <th>Indicador</th>
            <th class="text-center">Tipo Dado</th>
            <th class="text-center">Meta QTD</th>
            <th class="text-center">Meta Valor</th>
            <th class="text-center">Meta Perc</th>
            <th class="text-center">Ações</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in items" :key="item.id_meta_equipe">
            <td>{{ `${item.id_indicador} - ${item.indicador}` }}</td>
            <td class="text-center">{{ item.tipo_dado }}</td>
            <td class="text-center">{{ item.meta_qtde }}</td>
            <td class="text-center">
              {{ item.meta_valor | BrazilianCurrency }}
            </td>
            <td class="text-center">{{ item.meta_perc }}%</td>
            <td class="text-center">
              <v-btn
                icon
                v-can-access="564"
                @click="editItem(item)"
                color="orange"
                dark
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>

              <v-btn
                v-can-access="565"
                icon
                @click="deleteItem(item)"
                class="ml-2"
                color="red"
                dark
                small
              >
                <v-icon>mdi-trash-can</v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <ModalCadMetaEmpresa
      :dialog="dialog"
      :labelBtn="labelBtn"
      :editMetaEmpresa="meta"
      @close="dialog = false"
    />
  </div>
</template>

<script>
import comissao from "@/services/http/comissaoService";
import ModalCadMetaEmpresa from "./ModalCadMetaEmpresa";
import currencyMixin from "@/mixins/currencyMixin";
export default {
  props: {
    items: {
      type: Array
    }
  },

  components: {
    ModalCadMetaEmpresa
  },

  mixins: [currencyMixin],

  data() {
    return {
      dialog: false,
      labelBtn: "Salvar",
      meta: {}
    };
  },

  methods: {
    editItem(item) {
      this.meta = Object.assign({}, item);
      this.dialog = true;
      this.labelBtn = "Editar";
    },

    async deleteItem(item) {
      try {
        await this.$swal.confirm(
          "Deseja deletar o parâmetro?",
          "Essa ação não pode ser desfeita"
        );
        comissao()
          .metaEmpresa(item.id_meta_empresa)
          .delete(
            {},
            {
              notification: true,
              message: "Meta deletada com sucesso!"
            }
          );
      } catch (error) {
        console.log(error);
      }
    }
  }
};
</script>

<style></style>
