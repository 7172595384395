<template>
  <v-container class="container-card">
    <v-card>
      <Index />
    </v-card>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import Index from "@/components/comissao/metas/empresa/metaEmpresa/Index";

export default {
  components: {
    Index
  },

  data() {
    return {};
  },

  methods: {
    ...mapActions({
      fetchView: "sistema/fetchView"
    })
  },

  mounted() {
    this.fetchView("Meta Empresa");
  }
};
</script>

<style scoped>
.v-tab--active {
  background-color: white;
}
</style>
